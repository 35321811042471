import React from "react";
import styled from "styled-components";

interface AppPreviewProps {
    name: string;
    img: string;
    url: string;
}

export const AppPreview: React.FC<AppPreviewProps> = ({ name, url, img }) => {
    return <Wrapper href={url}>
        <ImgBox>
            <InnerImgBox>
                <img src={img} alt={name} />
            </InnerImgBox>
        </ImgBox>
        <LabelBox>
            {name}
        </LabelBox>
    </Wrapper>
}

const Wrapper = styled.a`
    display: flex;
    flex-direction: column;
    border: 1px solid #efefef;
    border-radius: 5px;
    background-color: white;
    flex-grow: 1;
    flex-basis: 0;
    cursor: pointer;
    text-decoration: none;
    color: #505050;
    min-width: 120px;
    max-width: 120px;
    
    &:hover {
        box-shadow: 10px 0px 10px 10px rgba(0, 0, 0, 0.1);
    }
`;

const ImgBox = styled.div`
    display: flex;    
    overflow: hidden;
    border-bottom: 1px solid #efefef;
    padding: 5px;
`;

const InnerImgBox = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    
    img {
        width: 100%;
        height: auto;
    }
`;

const LabelBox = styled.div`
    display: flex;
    padding: 5px;
`;