import React from "react";
import { ReactComponent as SignatureSvg } from "../../assets/img/signature.svg";
import styled from "styled-components";

export const Layout: React.FC = ({ children }) => {
    return <Wrapper>
        <SignatureWrapper>
            <SignatureSvg style={{ width: "100%", height: "auto" }}/>
        </SignatureWrapper>
        <Content>
            {children}
        </Content>
    </Wrapper>;
}

const Content = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: min(700px, 100%);
    align-items: center;
    gap: 20px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #597e6e;
    padding: 20px;
    align-items: center;
    width: calc(100% - 40px);
`;

const SignatureWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
`;