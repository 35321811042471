import React from 'react';
import './App.css';
import {AppPreview, Layout} from './components';
import mortalCoilImg from "./assets/img/mortal-coil.png";
import mrRubatoImg from "./assets/img/mr-rubato-square.png";
import anagramzImg from "./assets/img/anagramz.jpg";
import crosswordleImg from "./assets/img/crosswordle.png";
import deweyImg from "./assets/img/dewey.jpeg";

function App() {
  return (
    <Layout>
      <AppPreview name="Mortal Coil" img={mortalCoilImg} url="https://mortal-coil.xyz"/>
      <AppPreview name="Mr. Rubato" img={mrRubatoImg} url="https://determined-mahavira-bf48b6.netlify.app/" />
      <AppPreview name="Anagramz" img={anagramzImg} url="https://anagramz.xyz" />
      <AppPreview name="Crosswordle" img={crosswordleImg} url="https://crosswordle.xyz" />
      <AppPreview name="Dewey" img={deweyImg} url="https://dewey.cglyph.xyz" />
    </Layout>
  );
}

export default App;
